<div class="btn-group btn-group-sm" dropdown placement="bottom right">
    <button
        id="button-basic"
        dropdownToggle
        type="button"
        class="btn btn-outline-primary dropdown-toggle d-flex align-items-center"
        aria-controls="dropdown-basic"
    >
        <img class="me-1" alt="" src="/assets/flags/{{ languageId }}.png" />
        <span *ngIf="languageId === 'nl'">Nederlands</span>
        <span *ngIf="languageId === 'en'">English</span>
        <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
        <li role="menuitem">
            <a class="dropdown-item d-flex align-items-center" (click)="goToLanguage('nl')">
                <img class="me-1" alt="" ng-reflect-src="/assets/flags/nl.png" src="/assets/flags/nl.png" /> Nederlands
            </a>
        </li>
        <li role="menuitem">
            <a class="dropdown-item d-flex align-items-center" (click)="goToLanguage('en')">
                <img class="me-1" alt="" ng-reflect-src="/assets/flags/en.png" src="/assets/flags/en.png" /> English
            </a>
        </li>
    </ul>
</div>
