import { NgIf } from '@angular/common'
import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { LocaleShort, I18nService } from 'app/_services/i18n.service'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

@Component({
    selector: 'i18n-toggle',
    templateUrl: './i18n-toggle.component.html',
    standalone: true,
    imports: [NgIf, BsDropdownModule],
})
export class I18nToggleComponent {
    constructor(private i18nService: I18nService, private router: Router) {}

    get languageId() {
        return this.i18nService.languageId
    }

    goToLanguage(languageIdNew: LocaleShort) {
        const languageIdOld = this.i18nService.languageId
        const localeNew = this.i18nService.idLocaleMap[languageIdNew]
        this.i18nService.locale = localeNew

        this.router.navigateByUrl(this.router.url.replace(languageIdOld, languageIdNew))
    }
}
